<template>
  <div v-if="settings">
    <v-card class="authentication-rules">
      <v-card-title>
        <span>{{ $t('t.AuthenticationRules') }}</span>
        <v-btn
          class="ml-4"
          :disabled="!isDirty || (!hasDescription && !!modelRegexPattern.pattern) || !validSettings"
          fab
          x-small
          color="success"
          @click.stop="save()"
        >
          <v-icon ref="check">{{ $icon('i.Checked') }}</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="!isDirty"
          fab
          x-small
          color="warning"
          @click.stop="load()"
        >
          <v-icon>{{ $icon('i.Undo') }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-subheader>
          <span class="auth-rules-subsection-title">{{ $t('t.Configuration') }} {{ $t('t.InfoZeroInactive') }}&nbsp;</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-form
          v-model="validSettings"
          lazy-validation
        >
          <ul class="responsive-grid mt-4">
            <v-text-field
              v-model.number="settings.connectionAttemptNumber"
              :label="$t('t.ConnectionAttemptNumber')"
              type="number"
              :rules="[rules.numbers, rules.maxConfValue]"
              min='0'
              max='50'
            />
            <v-text-field
              v-model.number="settings.numberPasswordToSave"
              :label="$t('t.NumberPasswordUniqueness')"
              type="number"
              :rules="[rules.numbers, rules.maxConfValue]"
              min='0'
              max='50'
            />
            <v-text-field
              v-model.number="settings.expirationDaysNumber"
              :label="$t('t.ExpirationDaysNumber')"
              type="number"
              :rules="[rules.numbers, rules.bigMaxConfValue]"
              min='0'
              max='800'
            />
            <v-switch
              dense
              v-model="settings.isNotEqualWithUserName"
              :label="$t('t.IsNotEqualWithUserName')"
            />
          </ul>
        </v-form>
        <v-subheader>
          <span class="auth-rules-subsection-title">{{ $t('t.RegexPattern') }}&nbsp;</span>
        </v-subheader>
        <v-divider></v-divider>
        <v-form
          v-model="validRegexSettings"
          lazy-validation
          ref="regexGeneratorForm"
        >
          <ul class="responsive-grid mt-4">
            <v-text-field
              v-model.number="tmpRegexValues.charMin"
              :label="$t('t.RegexCharMin')"
              type="number"
              :rules="[rules.numbers, rules.regexNumber, rules.regexBelowMaxChar]"
              min='0'
            />
            <v-text-field
              v-model.number="tmpRegexValues.charMax"
              :label="$t('t.RegexCharMax')"
              type="number"
              :rules="[rules.numbers, rules.regexNumber]"
              @change="maxCharChanged"
              min='0'
            />
            <v-text-field
              v-model.number="tmpRegexValues.uppercaseMin"
              :label="$t('t.RegexUppercase')"
              type="number"
              :rules="[rules.numbers, rules.regexNumber, rules.regexBelowMaxChar]"
              min='0'
            />
            <v-text-field
              v-model.number="tmpRegexValues.lowercaseMin"
              :label="$t('t.RegexLowercase')"
              type="number"
              :rules="[rules.numbers, rules.regexNumber, rules.regexBelowMaxChar]"
              min='0'
            />
            <v-text-field
              v-model.number="tmpRegexValues.numberMin"
              :label="$t('t.RegexNumber')"
              type="number"
              :rules="[rules.numbers, rules.regexNumber, rules.regexBelowMaxChar]"
              min='0'
            />
            <v-text-field
              v-model.number="tmpRegexValues.specialMin"
              :label="`${$t('t.RegexSpecialChar')} : '-+!* $@% _<>`"
              type="number"
              :rules="[rules.numbers, rules.regexNumber, rules.regexBelowMaxChar]"
              min='0'
            />
          </ul>
        </v-form>
        <v-btn
          class="ml-4"
          color="primary"
          :disabled="!validRegexSettings"
          @click.stop="generateRegex()"
        >
          {{ $t('t.RegexGenerate') }}
        </v-btn>
        <v-text-field
          class="no-grid-element"
          :label="$t('t.RegexPattern')"
          v-model="modelRegexPattern.pattern"
          readonly
        />
        <v-text-field
          class="no-grid-element"
          :label="$t('t.TestRegex')"
          :rules="[rules.regex]"
          :disabled="!modelRegexPattern.pattern"
          clearable
        />

        <!-- A bit hacky, but needed to update the error when we firt create the rules  -->
        <v-form
          ref="translationsForm"
          lazy-validation
          :disabled="!modelRegexPattern.pattern"
        >
          <translations
            class="no-grid-element"
            :label="$t('t.Name')"
            :translations.sync="computedDescriptions"
            :form="true"
            :rules="[rules.translations]"
          >
          </translations>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {
    Translations: () => import('@/components/translations')
  },
  mounted () {
    this.load()
  },
  activated () {
    this.load()
  },
  computed: {
    isDirty () {
      const trimmedModelRegexPattern = {
        pattern: this.modelRegexPattern.pattern,
        patternTranslatedValues: this.modelRegexPattern.patternTranslatedValues
      }
      const trimmedInitialModelRegexPattern = {
        pattern: this.initialModelRegexPattern.pattern,
        patternTranslatedValues: this.initialModelRegexPattern.patternTranslatedValues
      }

      return !(JSON.stringify(this.settings) === JSON.stringify(this.initialSettings) && JSON.stringify(trimmedModelRegexPattern) === JSON.stringify(trimmedInitialModelRegexPattern))
    },
    computedDescriptions: {
      get () {
        const translations = {}

        for (const v of this.modelRegexPattern.patternTranslatedValues) {
          translations[v.culture] = v.description
        }

        return translations
      },
      set (v) {
        for (const key in v) {
          const translationRef = this.modelRegexPattern.patternTranslatedValues.find(value => value.culture === key)

          if (translationRef) {
            translationRef.description = v[key]
          }
        }
        this.$refs.translationsForm.validate()
      }
    },
    hasDescription () {
      for (const v of this.modelRegexPattern.patternTranslatedValues) {
        if (v.description && v.description !== '') {
          return true
        }
      }
      return false
    }
  },
  data () {
    return {
      isPatternNew: true,
      initialSettings: null,
      settings: null,
      initialModelRegexPattern: null,
      modelRegexPattern: {
        pattern: '',
        patternTranslatedValues: [
          { culture: 'en-US', description: '' },
          { culture: 'fr-CA', description: '' },
          { culture: 'it-IT', description: '' },
          { culture: 'es-ES', description: '' },
          { culture: 'pt-BR', description: '' }
        ],
        charMin: 0,
        charMax: 0,
        uppercaseMin: 0,
        lowercaseMin: 0,
        numberMin: 0,
        specialMin: 0
      },
      tmpRegexValues: {
        charMin: 0,
        charMax: 0,
        uppercaseMin: 0,
        lowercaseMin: 0,
        numberMin: 0,
        specialMin: 0
      },
      rules: {
        numbers: value => {
          return /^[0-9]+$/gm.test(value)
        },
        regexNumber: value => {
          try {
            return parseInt(value, 10) <= 50
          } catch (e) {
          }

          return false
        },
        regex: value => {
          try {
            if (!value?.length) {
              return true
            }

            const pattern = new RegExp(this.modelRegexPattern.pattern)

            return pattern.test(value)
          } catch (e) {
          }

          return false
        },
        regexBelowMaxChar: value => {
          if (this.tmpRegexValues.charMax === 0) {
            return true
          }

          try {
            return this.tmpRegexValues.charMax >= parseInt(value, 10) || this.$t('t.ValueGreaterThanCharMax')
          } catch (e) {
          }
          return false
        },
        translations: value => {
          return !this.modelRegexPattern.pattern || this.hasDescription || value?.length > 0 || this.$t('t.AtLeastOneDescription')
        },
        maxConfValue: value => {
          try {
            return parseInt(value) <= 50 || this.$t('t.OverMaxValue', { maxValue: 50 })
          } catch (e) { }

          return false
        },
        bigMaxConfValue: value => {
          try {
            return parseInt(value) <= 800 || this.$t('t.OverMaxValue', { maxValue: 800 })
          } catch (e) { }

          return false
        }
      },
      validSettings: false,
      validRegexSettings: false
    }
  },
  methods: {
    async load () {
      const r = await this.$http().get('/core/v6/settings/authentication-rules')

      this.settings = r?.data

      if (this.settings?.regexPattern) {
        this.modelRegexPattern = Object.assign({}, this.settings.regexPattern)
        this.isPatternNew = false
      }
      // Deep cloning
      this.initialModelRegexPattern = JSON.parse(JSON.stringify(this.modelRegexPattern))
      this.initialSettings = Object.assign({}, r?.data)

      for (const k in this.tmpRegexValues) {
        this.tmpRegexValues[k] = this.modelRegexPattern[k]
      }
    },
    async save () {
      const settingsCopy = Object.assign({}, this.settings)

      const trimmedModelRegexPattern = {
        pattern: this.modelRegexPattern.pattern,
        patternTranslatedValues: this.modelRegexPattern.patternTranslatedValues
      }
      const trimmedInitialModelRegexPattern = {
        pattern: this.initialModelRegexPattern.pattern,
        patternTranslatedValues: this.initialModelRegexPattern.patternTranslatedValues
      }

      if (!(JSON.stringify(trimmedModelRegexPattern) === JSON.stringify(trimmedInitialModelRegexPattern))) {
        settingsCopy.regexPattern = this.modelRegexPattern
      }

      await this.$http().post('/core/v6/settings/authentication-rules', settingsCopy)
        .then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SaveConfirmation'))
          this.load()
          this.$http().get('/settings?key=authenticationRules&forceReloadCache=true').catch(_ => { })
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
    },
    generateRegex () {
      const regexNumberMin = '.*[0-9]'
      const regexUppercaseMin = '.*[A-Z]'
      const regexLowercaseMin = '.*[a-z]'
      const regexSpecialMin = '.*[-+!*$@%_<>]'
      const startRegex = '(?='

      Object.assign(this.modelRegexPattern, this.tmpRegexValues)

      this.modelRegexPattern.pattern = ''

      if (this.modelRegexPattern.uppercaseMin > 0) {
        const counter = (this.modelRegexPattern.uppercaseMin === 1) ? '+' : `{${this.modelRegexPattern.uppercaseMin},}`
        this.modelRegexPattern.pattern += `${startRegex}(?:${regexUppercaseMin})${counter})`
      }

      if (this.modelRegexPattern.lowercaseMin > 0) {
        const counter = (this.modelRegexPattern.lowercaseMin === 1) ? '+' : `{${this.modelRegexPattern.lowercaseMin},}`
        this.modelRegexPattern.pattern += `${startRegex}(?:${regexLowercaseMin})${counter})`
      }

      if (this.modelRegexPattern.numberMin > 0) {
        const counter = (this.modelRegexPattern.numberMin === 1) ? '+' : `{${this.modelRegexPattern.numberMin},}`
        this.modelRegexPattern.pattern += `${startRegex}(?:${regexNumberMin})${counter})`
      }

      if (this.modelRegexPattern.specialMin > 0) {
        const counter = (this.modelRegexPattern.specialMin === 1) ? '+' : `{${this.modelRegexPattern.specialMin},}`
        this.modelRegexPattern.pattern += `${startRegex}(?:${regexSpecialMin})${counter})`
      }

      if (this.modelRegexPattern.charMax !== 0 || this.modelRegexPattern.charMin !== 0) {
        this.modelRegexPattern.pattern += '(.{'
        this.modelRegexPattern.pattern += (this.modelRegexPattern.charMin === 0 ? '' : this.modelRegexPattern.charMin) + ','
        this.modelRegexPattern.pattern += (this.modelRegexPattern.charMax === 0 ? '' : this.modelRegexPattern.charMax)
        this.modelRegexPattern.pattern += '})'
      } else {
        this.modelRegexPattern.pattern += '.+'
      }

      this.modelRegexPattern.pattern = '^' + this.modelRegexPattern.pattern + '$'

      if (this.modelRegexPattern.pattern.length <= 2) {
        this.modelRegexPattern.pattern = ''
      }

      this.$refs.translationsForm.validate()
    },
    maxCharChanged () {
      this.$refs.regexGeneratorForm.validate()
    },
    translationChanged () {

    }
  }
}
</script>

<style lang="stylus">
.responsive-grid
  display grid
  grid-template-columns repeat(auto-fill, minmax(15rem, auto))
  column-gap 1em

.no-grid-element
  padding 2em
  padding-bottom 0em

.regex-desc-area
  min-width 50em

.translation-grid
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-template-rows repeat(auto-fill, 1fr)
</style>
